import classnames from 'classnames/bind'
import React, { useRef } from 'react'
import { Card } from '~/@types/card'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalGridPreset } from '~/@types/grid-preset'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextSize } from '~/@types/text-size'
import { GlobalTextStyling } from '~/@types/text-styling'

import Slider from '@unlikelystudio/react-slider'

import RichText, { RichTextBlocks } from '~/components/Abstracts/RichText'
import EditoCard from '~/components/Slices/EditoCard'

import { useSliceProvider } from '~/providers/SliceProvider'
import { useStyle } from '~/providers/StyleProvider'

import useTrackingPromotionImpression from '~/hooks/useTrackingPromotionImpression'

import css from './styles.module.scss'

const cx = classnames.bind(css)

const TITLE_SIZES = {
  [GlobalTextSize.Big]: GlobalTextPreset.Title30_60HafferSemiBold,
  [GlobalTextSize.Small]: GlobalTextPreset.Title18_24HafferSemiBold,
}
export interface CategoryGridEditoProps {
  className?: string
  title?: RichTextBlocks
  items?: Card[]
  layout?: GlobalTextSize
}

const IMAGE_SIZES = [
  { breakpoint: 'md', ratio: 310 / 1320 },
  { ratio: 170 / 390 },
]

function CategoryGridEdito({
  className,
  title,
  items,
  layout,
}: CategoryGridEditoProps) {
  const { ref } = useTrackingPromotionImpression()
  const gridStyle = useStyle({ grid: GlobalGridPreset.BASE_GRID })
  const titleStyle = useStyle({
    textPreset: TITLE_SIZES[layout],
    color: GlobalThemeColors.Black,
    textStyling: GlobalTextStyling.UpperCase,
  })

  const customSliderRef = useRef()

  const { position: slicePosition } = useSliceProvider()

  return (
    <div ref={ref} className={cx(css.CategoryGridEdito, className)}>
      <div className={cx(css.content, gridStyle)} ref={customSliderRef}>
        <RichText className={cx(css.title, titleStyle)} render={title} />

        <Slider
          className={css.slider}
          snap={true}
          customSliderRef={customSliderRef}>
          {items?.map((card, index) => (
            <EditoCard
              className={css.card}
              key={`item_${index}`}
              sizesFromBreakpoints={IMAGE_SIZES}
              priority={slicePosition === 1}
              {...card}
            />
          ))}
        </Slider>
      </div>
    </div>
  )
}

CategoryGridEdito.defaultProps = {}

export default CategoryGridEdito
