import classnames from 'classnames/bind'
import { Card } from '~/@types/card'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextStyling } from '~/@types/text-styling'

import RichText from '~/components/Abstracts/RichText'
import Image from '~/components/UI/Image'
import Link from '~/components/UI/Link'

import { useStyle } from '~/providers/StyleProvider'

import css from './styles.module.scss'

const cx = classnames.bind(css)

const IMAGE_SIZES = [{ breakpoint: 'md', ratio: 6 / 24 }, { ratio: 12 / 24 }]

function EditoCard({
  className,
  name,
  image,
  link,
  sizesFromBreakpoints,
  ratio,
  pin,
}: Card) {
  const cardTitleStyle = useStyle({
    textPreset: GlobalTextPreset.Title10_14HafferSemiBold,
    color: GlobalThemeColors.Black,
    textStyling: GlobalTextStyling.UpperCase,
  })

  const pinStyle = useStyle({
    textPreset: GlobalTextPreset.Label10_12HafferSemiBold,
    color: GlobalThemeColors.Black,
    textStyling: GlobalTextStyling.UpperCase,
  })

  return (
    <Link className={cx(css.EditoCard, className)} {...link}>
      {image && (
        <Image
          className={css.image}
          layout="fill"
          objectFit="cover"
          ratio={ratio ?? css.ratio}
          sizesFromBreakpoints={sizesFromBreakpoints ?? IMAGE_SIZES}
          asPlaceholder
          {...image}
        />
      )}

      {name && <h3 className={cx(css.title, cardTitleStyle)}>{name}</h3>}

      <RichText className={cx(css.pin, pinStyle)} render={pin} />
    </Link>
  )
}

EditoCard.defaultProps = {}

export default EditoCard
