import mapSliceToComponent from '~/imports/error_page'

import ErrorLinks from '~/components/Slices/ErrorLinks'
import Slice404 from '~/components/Slices/Slice404'
import SliceManager from '~/components/UI/SliceManager'

import { ClientPageView } from '~/providers/GTMTrackingProvider/ClientPageView'

import getErrorPageStaticPropsFunction from '~/data/error-page-data'
import { ErrorPageProps } from '~/data/error-page-data/serializer'

function Page404({ errorPage, slices, errorLinks }: ErrorPageProps) {
  return (
    <>
      {errorPage && <Slice404 {...errorPage} />}
      <SliceManager
        mapSliceTypeToComponent={mapSliceToComponent}
        slicesData={slices}
      />
      {errorLinks && <ErrorLinks {...errorLinks} />}
      <ClientPageView template="404" />
    </>
  )
}

export default Page404

export const getStaticProps = getErrorPageStaticPropsFunction()
